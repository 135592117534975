@import '../../../../styles/_variables.scss';
@import '../../../../components/common/button/button-variables';
@import '../../../../components/common/typography/typography-variables';
@import '../../../../styles/_mixins.scss';

.catalog-product-price-list-item {
  $self: &;

  position: relative;
  padding: 4px 8px;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: $blue8;
  transition: none;

  &_disabled {
    pointer-events: all;
  }

  &_animation_crossfade {
    * {
      animation: text-crossfade 0.5s ease-in-out 1.5s;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include inter_12_16;
    @include font-weight-regular;
    text-align: left;

    &:before {
      content: attr(data-content);
      color: $colorTextSecondary;
    }

    &_unavailable:before {
      text-decoration: line-through;
    }
  }

  &__vendor {
    @include font-weight-regular;
    width: 100%;
    text-align: left;
    visibility: hidden;
    font-size: 0;
  }

  &__buy-label {
    display: none;
    @include font-weight-regular;
    width: 100%;
    text-align: left;
    color: $colorTextPrimary;

    &:before {
      content: attr(data-content);
    }
  }

  &__done-label {
    @include position(absolute, $top: 4px, $left: 8px);
    @include font-weight-regular;
    color: $colorTextSecondary;
    text-align: left;
  }

  &__price {
    @include inter_12_16;
    @include font-weight-medium;
    @include uppercase;
    color: $colorTextPrimary;

    &_latest {
      text-decoration: line-through;
      color: $colorTextSecondary;
    }

    &_not-in-stock {
      color: $colorTextSecondary;
    }
  }

  &__badge {
    @include position(absolute, $top: -12px, $right: -8px);
  }

  &_adding-to-cart {
    background-color: $yellow2;

    #{$self}__info:before {
      opacity: 0;
    }

    #{$self}__price {
      color: $colorTextSecondary;
    }

    #{$self}__buy-label {
      opacity: 0;
    }
  }
}

@include desktop() {
  .catalog-product-price-list-item {
    $self: &;

    padding: 6px 10px 4px 10px;
    height: 48px;
    background-color: $blue9;

    &__price {
      @include inter_15_22;
      @include font-weight-medium;

      &_latest {
        color: $colorTextSecondary;
      }

      &_not-in-stock {
        color: $colorTextSecondary;
      }
    }

    &__done-label {
      @include position(absolute, $top: 6px, $left: 10px);
    }

    &:focus,
    &:hover:not(#{$self}_disabled, #{$self}_skeleton) {
      background-color: $yellow2;

      #{$self}__info:before {
        color: transparent;
        height: 0;
        visibility: hidden;
      }

      #{$self}__buy-label {
        display: block;
      }
    }
  }
}
