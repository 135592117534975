@import '../../../components/common/typography/typography-variables';
@import '../../../styles/_variables.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/animations';

$letter-space: 0.3em;

$dotsPosition: 0 0;
$eyePosition: 0 -16px;
$activeEyePosition: 0 -32px;

.sku-code {
  $self: &;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0 $letter-space;
  @include add-letter-spacing;

  &:hover {
    #{$self}__symbol {
      background-position: $eyePosition;

      &:hover {
        background-position: $activeEyePosition;
      }
    }
  }

  &__symbol {
    cursor: pointer;
    height: 16px;
    width: 28px;
    min-width: 28px;
    background-image: url('../../../../assets/images/icons/sku-sprite.svg');
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: $dotsPosition;
  }
}

@include mobile() {
  .sku-code__symbol {
    background-position: $eyePosition;
  }
}
