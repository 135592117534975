@import '../../../../styles/variables';
@import '../../../../components/common/typography/typography-variables';
@import '../../../../styles/mixins';

$image-border-radius: 6px;

$grid-item: '.catalog-product-grid-item';

#{$grid-item} {
  $self: &;

  width: 100%;
  flex: 0;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  border-bottom: 1px solid $blue8;

  &__name {
    @include inter_13_18;
    white-space: normal;
    visibility: hidden;
    position: relative;

    &_nowrap {
      @include truncate();
    }

    &:before {
      content: attr(data-content);
      visibility: visible;
      position: absolute;
      width: 100%;
    }
  }

  &__wrap {
    height: 100%;
    border-right: none;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  &__info-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 16px 20px;
    gap: 12px;
  }

  &__image-container {
    height: 94px;
    width: 94px;
    position: relative;
    overflow: hidden;
    border-radius: $image-border-radius;
    align-self: end;
    flex-shrink: 0;

    &_default {
      display: none;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: hidden;
  }

  &__image {
    height: 105px;
    width: 100%;
    object-position: center;
    object-fit: cover;
  }

  &__black-cover-image {
    @include black-cover();
  }

  &__scheme-number {
    display: none;
  }

  &__codes {
    &_skeleton {
      margin-top: 4px;
    }

    #{$self}__sku {
      display: flex;
      @include inter_12_16;
      @include font-weight-medium;
      @include uppercase;
      margin-top: 8px;
    }
  }

  &__price-label {
    @include inter_12_16;
    @include font-weight-medium;
    @include uppercase;
    text-transform: none;
  }

  &__price-link {
    position: relative;
  }

  &__prices-wrapper {
    overflow-x: scroll;
    padding: 12px 0 0 0;
    text-decoration: none;

    @include hide-scroll;

    &:after {
      content: '';
      width: 40px;
      height: 100%;
      background: linear-gradient(267.78deg, $white 46.39%, rgba(255, 255, 255, 0) 98.21%);
      @include position(absolute, $right: -20px, $bottom: 1px);
    }
  }

  &__basket-button {
    width: 100%;
    height: 44px;
    padding: 0 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include truncate();

    &_animation_crossfade {
      * {
        animation: text-crossfade 0.5s ease-in-out 1.5s;
      }
    }
  }
}

$placeholderPositions: 0 0, 0 -120px, 0 -240px, 0 -360px, 0 -490px, 0 -360px, 0 -240px, 0 -120px;

@include desktop() {
  @for $i from 1 through length($placeholderPositions) {
    #{$grid-item}:nth-child(#{$i}n) {
      #{$grid-item}__placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('../../../../../assets/images/placeholder-sprite.svg');
        background-position: nth($placeholderPositions, $i);
        width: 185px;
        height: 120px;
        background-repeat: no-repeat;
        vertical-align: middle;

        &:before {
          content: attr(data-content);
          @include inter_20_28;
          color: $black6;
        }
      }
    }
  }

  #{$grid-item} {
    $self: &;
    width: 265px;
    flex: 1 0 265px;
    min-height: 309px;
    margin: 0 -1px -1px 0;
    border-bottom: 1px solid $blue8;
    border-right: 1px solid $blue8;

    &:empty {
      overflow: hidden;
      height: 0;
      min-height: 0;
      border: none;
      padding: 0;
    }

    &__image {
      height: 169px;
    }

    &__name {
      @include inter_15_22;
      @include font-weight-regular;
      @include truncate();
      width: fit-content;
      max-width: 100%;

      &:before {
        @include truncate();
      }

      &:hover,
      &:focus {
        white-space: normal;
      }
    }

    &__codes {
      #{$self}__sku {
        &:not(:first-child) {
          display: none;
        }

        &:first-child:not(:only-child) {
          &:after {
            content: ' / \a0 Цена:';
            @include inter_12_16;
            @include font-weight-medium;
            @include uppercase;
            text-transform: none;
            margin-left: 8px;
          }
        }
      }

      &:hover {
        color: inherit;
      }
    }

    &__info-container {
      flex-direction: column;
      padding: 20px 20px 6px;
    }

    &__info {
      margin-right: 0;
    }

    &__price-link {
      width: 100%;
    }

    &__image-container {
      height: 152px;
      min-width: 100%;
      align-self: start;

      &_default {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__scheme-number {
      display: block;
      @include position(absolute, $left: 20px, $top: 16px);
      color: $colorTextSecondary;
      background-image: url('../../../../../assets/images/crnr.svg');
      background-repeat: no-repeat;
      background-position: 0 100%;
      padding: 0 10px 0 0;
      min-height: 27px;
    }

    &__prices-wrapper {
      padding-right: 20px;
      padding-bottom: 12px;
    }

    &:focus &,
    &:hover:not(#{$self}_skeleton) & {
      &__wrap {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        width: 100%;
        height: auto;
        background-color: $white;
        min-height: 100%;
        @include position(absolute, $left: 0, $top: 0, $z-index: 1);

        #{$self}__button-buy:not(#{$self}__button-buy:hover) {
          background-color: $blue8;
        }
      }

      &__name {
        white-space: normal;
        visibility: visible;

        &:before {
          content: none;
        }
      }

      &__codes {
        #{$self}__sku {
          display: flex;

          &:after {
            content: none;
          }
        }
      }
    }
  }
}
