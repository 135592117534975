@import '../../common/button/button-variables';

.catalog-product-price-list {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  width: max-content;

  &_disabled {
    height: 48px;
  }
}
